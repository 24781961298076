/*@jsxRuntime classic @jsx React.createElement @jsxFrag React.Fragment*/
import {useMDXComponents as _provideComponents} from "@mdx-js/react";
import React from "react";
function _createMdxContent(props) {
  const _components = Object.assign({
    pre: "pre",
    code: "code",
    h1: "h1",
    a: "a",
    div: "div",
    p: "p",
    ul: "ul",
    li: "li",
    strong: "strong",
    hr: "hr"
  }, _provideComponents(), props.components);
  return React.createElement(React.Fragment, null, React.createElement(_components.pre, null, React.createElement(_components.code, {
    className: "language-jsx"
  }, "<div className=\"bg-neutral-20 p-2\">\n\t<BodyText>\n\t\t<span>ServiceTitan Inc.</span>\n\t</BodyText>\n\t<Divider spacing=\"2\" />\n\t<BodyText size=\"small\">\n\t\t<span>801 N Brand Blvd Suite 700, Glendale, CA 91203, United States</span>\n\t</BodyText>\n</div>\n")), "\n", React.createElement(_components.h1, {
    id: "vertical",
    style: {
      position: "relative"
    }
  }, "Vertical", React.createElement(_components.a, {
    href: "#vertical",
    "aria-label": "vertical permalink",
    className: "anchor after"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  }))), "\n", React.createElement(_components.p, null, "Add the ", React.createElement(_components.code, null, "vertical"), " prop in order to set the divider vertically-oriented."), "\n", React.createElement(_components.pre, null, React.createElement(_components.code, {
    className: "language-jsx"
  }, "<div className=\"bg-blue-100\">\n\t<BodyText className=\"p-2\">\n\t\t<span className=\"m-r-1\">Download:</span>\n\t\t<Link>iPhone</Link>\n\t\t<Divider vertical spacing=\"1\" />\n\t\t<Link>iPad</Link>\n\t\t<Divider vertical spacing=\"1\" />\n\t\t<Link>Android</Link>\n\t\t<Divider vertical spacing=\"1\" />\n\t\t<Link>Windows</Link>\n\t</BodyText>\n\t<Divider />\n\t<Stack className=\"w-100\">\n\t\t<Link className=\"p-2\" style={{ whiteSpace: 'nowrap', alignSelf: 'center' }}>\n\t\t\t<Icon name=\"phone\" className=\"m-r-half\" size=\"18\" />\n\t\t\t(855) 899-0970\n\t\t</Link>\n\t\t<Divider vertical spacing=\"1\" className=\"p-y-half\" />\n\t\t<span className=\"p-2\">\n\t\t\t801 N Brand Blvd Suite 700, Glendale, CA 91203, United States\n\t\t</span>\n\t</Stack>\n</div>\n")), "\n", React.createElement(_components.h1, {
    id: "spacing",
    style: {
      position: "relative"
    }
  }, "Spacing", React.createElement(_components.a, {
    href: "#spacing",
    "aria-label": "spacing permalink",
    className: "anchor after"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  }))), "\n", React.createElement(_components.p, null, "Provides control over Divider's gutter size."), "\n", React.createElement(_components.pre, null, React.createElement(_components.code, {
    className: "language-jsx"
  }, "<div className=\"p-2 bg-neutral-0\">\n\t<Divider spacing=\"0\" />\n\t<Divider spacing=\"half\" />\n\t<Divider spacing=\"1\" />\n\t<Divider spacing=\"2\" />\n\t<Divider spacing=\"3\" />\n\t<Divider spacing=\"4\" />\n\t<Divider spacing=\"5\" />\n</div>\n")), "\n", React.createElement(_components.h1, {
    id: "divider-with-content",
    style: {
      position: "relative"
    }
  }, "Divider with content", React.createElement(_components.a, {
    href: "#divider-with-content",
    "aria-label": "divider with content permalink",
    className: "anchor after"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  }))), "\n", React.createElement(_components.p, null, "Divider may have a small piece of content, placed (by default) in its center."), "\n", React.createElement(_components.pre, null, React.createElement(_components.code, {
    className: "language-jsx"
  }, "<Stack direction=\"column\" className=\"w-50 m-auto\">\n\t<Stack.Item className=\"ta-center p-b-4 p-t-3 bg-blue-100\">\n\t\t<Headline>Pro</Headline>\n\t\t<BodyText className=\"p-b-1\">$19 per member / month</BodyText>\n\t\t<Button primary>Buy Pro</Button>\n\t</Stack.Item>\n\t<Divider className=\"bg-blue-100\">\n\t\t<Eyebrow\n\t\t\tclassName=\"m-x-half\"\n\t\t\tstyle={{ marginTop: '-.7em', marginBottom: '-.7em' }}\n\t\t>\n\t\t\tOr\n\t\t</Eyebrow>\n\t</Divider>\n\t<Stack.Item className=\"ta-center p-b-4 p-t-3 bg-blue-100\">\n\t\t<Headline>Business</Headline>\n\t\t<BodyText className=\"p-b-1\">$99 per member / month</BodyText>\n\t\t<Button primary>Buy Business</Button>\n\t</Stack.Item>\n\t<Divider className=\"bg-blue-100\">\n\t\t<div style={{ marginTop: '-.6em', marginBottom: '-.6em' }}>\n\t\t\t<Icon name=\"help\" className=\"m-x-half\" color=\"gray\" />\n\t\t</div>\n\t</Divider>\n\t<Stack.Item className=\"ta-center p-3 bg-neutral-30\">\n\t\t<BodyText size=\"small\">\n\t\t\t<Link>Contact Sales</Link>\n\t\t</BodyText>\n\t</Stack.Item>\n</Stack>\n")), "\n", React.createElement(_components.h1, {
    id: "align-content",
    style: {
      position: "relative"
    }
  }, "Align content", React.createElement(_components.a, {
    href: "#align-content",
    "aria-label": "align content permalink",
    className: "anchor after"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  }))), "\n", React.createElement(_components.p, null, "Controls the alignment of content on the horizontal or vertical axis."), "\n", React.createElement(_components.pre, null, React.createElement(_components.code, {
    className: "language-jsx"
  }, "<div>\n\t<Divider alignContent=\"start\">\n\t\t<Icon className=\"m-x-half\" name=\"chevron_left\" size=\"24\" />\n\t</Divider>\n\t<Divider alignContent=\"center\">\n\t\t<Icon className=\"m-x-half\" name=\"code\" size=\"24\" />\n\t</Divider>\n\t<Divider alignContent=\"end\">\n\t\t<Icon className=\"m-x-half\" name=\"chevron_right\" size=\"24\" />\n\t</Divider>\n\t<Stack>\n\t\t<BodyText className=\"m-2\">\n\t\t\tLorem ipsum dolor sit amet.\n\t\t</BodyText>\n\t\t<Divider vertical alignContent=\"start\">\n\t\t\t<Icon className=\"m-y-half\" name=\"vertical_align_top\" size=\"24\" />\n\t\t</Divider>\n\t\t<BodyText className=\"m-2\">\n\t\t\tConsectetur adipiscing elit.\n\t\t</BodyText>\n\t\t<Divider vertical alignContent=\"center\">\n\t\t\t<Icon className=\"m-y-half\" name=\"vertical_align_center\" size=\"24\" />\n\t\t</Divider>\n\t\t<BodyText className=\"m-2\">\n\t\t\tIncididunt ut labore et dolore.\n\t\t</BodyText>\n\t\t<Divider vertical alignContent=\"end\">\n\t\t\t<Icon className=\"m-y-half\" name=\"vertical_align_bottom\" size=\"24\" />\n\t\t</Divider>\n\t\t<BodyText className=\"m-2\">\n\t\t\tUt enim ad minim veniam.\n\t\t</BodyText>\n\t</Stack>\n</div>\n")), "\n", React.createElement(_components.h1, {
    id: "color",
    style: {
      position: "relative"
    }
  }, "Color", React.createElement(_components.a, {
    href: "#color",
    "aria-label": "color permalink",
    className: "anchor after"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  }))), "\n", React.createElement(_components.p, null, "In certain situations the color also may be set using classname or style."), "\n", React.createElement(_components.pre, null, React.createElement(_components.code, {
    className: "language-jsx"
  }, "<>\n\t<div className=\"bg-red-200 p-2\">\n\t\t<BodyText>Lorem ipsum dolor sit amet.</BodyText>\n\t\t<Divider className=\"c-red-300\">\n\t\t\t<Eyebrow className=\"c-red-400 m-x-half\">Red</Eyebrow>\n\t\t</Divider>\n\t\t<BodyText>Duis aute irure dolor.</BodyText>\n\t</div>\n\t<div className=\"bg-blue-200 p-2\">\n\t\t<Stack>\n\t\t\t<BodyText style={{ flexBasis: '100%' }}>Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.</BodyText>\n\t\t\t<Divider vertical style={{ color: 'rgba(0, 0, 255, .2)' }}>\n\t\t\t\t<Eyebrow className=\"c-blue-400 m-x-2 m-y-half\">Blue</Eyebrow>\n\t\t\t</Divider>\n\t\t\t<BodyText style={{ flexBasis: '100%' }}>Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur.</BodyText>\n\t\t</Stack>\n\t</div>\n</>\n")), "\n", React.createElement(_components.h1, {
    id: "best-practices",
    style: {
      position: "relative"
    }
  }, "Best Practices", React.createElement(_components.a, {
    href: "#best-practices",
    "aria-label": "best practices permalink",
    className: "anchor after"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  }))), "\n", React.createElement(_components.ul, null, "\n", React.createElement(_components.li, null, "The divider renders as an ", React.createElement(_components.code, null, "<hr>"), " by default, but with proper styling."), "\n", React.createElement(_components.li, null, "Сontent must be small and clear, e.g. a short text wrapped into ", React.createElement(_components.strong, null, "Eyebrow"), " component or just an ", React.createElement(_components.strong, null, "Icon"), "."), "\n"), "\n", React.createElement(_components.hr), "\n", React.createElement(_components.h1, {
    id: "importing",
    style: {
      position: "relative"
    }
  }, "Importing", React.createElement(_components.a, {
    href: "#importing",
    "aria-label": "importing permalink",
    className: "anchor after"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  }))), "\n", React.createElement(_components.pre, null, React.createElement(_components.code, {
    className: "language-jsx"
  }, "code: true\n---\nimport { Divider } from '@servicetitan/design-system';\n")));
}
function MDXContent(props = {}) {
  const {wrapper: MDXLayout} = Object.assign({}, _provideComponents(), props.components);
  return MDXLayout ? React.createElement(MDXLayout, props, React.createElement(_createMdxContent, props)) : _createMdxContent(props);
}
export default MDXContent;
